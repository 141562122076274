import moment from 'moment'
import React, { useMemo } from 'react'
import { Button, VStack } from '@chakra-ui/react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { TextField, Select } from '@blueprinthq/joy'
import { BPDateTimePicker } from '@components'
import { CLIENT_PRONOUNS } from '@constants/clientPronouns'

const pronounTypes = Object.keys(CLIENT_PRONOUNS)

const baseSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  pronoun: Yup.string()
    .optional()
    .nullable()
})

const initialValues = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  pronoun: null
}

type FormValues = {
  firstName: string
  lastName: string
  dateOfBirth: string | null
  pronoun: string | null
}

interface ClientFormProps {
  isLoading: boolean
  onSubmit: ({ firstName, lastName, dateOfBirth, pronoun }: FormValues) => void
  isEvidenceBasedCareEnabled: boolean
  isDateOfBirthEnabled: boolean
}

export const AddClientForm = ({
  isLoading,
  onSubmit,
  isEvidenceBasedCareEnabled,
  isDateOfBirthEnabled
}: ClientFormProps) => {
  const validationSchema = useMemo(() => {
    let schema = baseSchema

    if (isEvidenceBasedCareEnabled) {
      schema = schema.shape({
        dateOfBirth: Yup.date()
          .max(moment(), 'Date of birth must be in the past')
          .required('Required')
      })
    }

    if (isDateOfBirthEnabled) {
      schema = schema.shape({
        dateOfBirth: Yup.date().max(
          moment(),
          'Date of birth must be in the past'
        )
      })
    }
    return schema
  }, [isEvidenceBasedCareEnabled, isDateOfBirthEnabled])

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isValid, dirty }) => (
        <Form style={{ width: '100%', maxWidth: '400px' }}>
          <VStack
            spacing={errors.firstName || errors.lastName ? 6 : 4}
            width="100%"
          >
            <Field name="firstName">
              {({ field }: { field: any }) => (
                <TextField
                  {...field}
                  label="First Name"
                  isInvalid={errors.firstName && touched.firstName}
                  errorText={errors.firstName}
                  isRequired
                  width="100%"
                  bg="white"
                />
              )}
            </Field>
            <Field name="lastName">
              {({ field }: { field: any }) => (
                <TextField
                  {...field}
                  label="Last Name"
                  isInvalid={errors.lastName && touched.lastName}
                  errorText={errors.lastName}
                  isRequired
                  width="100%"
                  bg="white"
                />
              )}
            </Field>
            {(isEvidenceBasedCareEnabled || isDateOfBirthEnabled) && (
              <Field name="dateOfBirth">
                {({ field }: { field: any }) => (
                  <BPDateTimePicker
                    {...field}
                    showDate
                    isRequired={isEvidenceBasedCareEnabled}
                    label="Date of Birth"
                    isInvalid={errors.dateOfBirth && touched.dateOfBirth}
                    errorText={
                      (errors.dateOfBirth &&
                        errors.dateOfBirth.includes('Invalid') &&
                        'Invalid date') ||
                      errors.dateOfBirth
                    }
                    width="100%"
                    bg="white"
                  />
                )}
              </Field>
            )}
            <Field name="pronoun">
              {({ field, form }: { field: any; form: any }) => (
                <Select
                  label="Pronouns"
                  borderColor="lightgrey"
                  fontSize="medium"
                  value={field.value}
                  onChange={value => form.setFieldValue('pronoun', value)}
                  options={pronounTypes}
                  width="100%"
                  bg="white"
                />
              )}
            </Field>
            <Button
              type="submit"
              isFullWidth
              isLoading={isLoading}
              isDisabled={!isValid || !dirty}
              height="48px"
            >
              Add Client
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  )
}
