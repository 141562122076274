import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { CompassOutlined, ChevronDown } from '@components/icons'

interface AssistDrawerHeaderProps {
  firstName?: string
  lastName?: string
  toggleAssistFullScreen: () => void
  isExpanded?: boolean
  showShadow?: boolean
}

export const AssistDrawerHeader: React.FC<AssistDrawerHeaderProps> = ({
  firstName,
  lastName,
  toggleAssistFullScreen,
  isExpanded = false,
  showShadow = true
}) => {
  return (
    <Box
      aria-label="Assist"
      bg="white"
      borderTopRadius="24px"
      h="72px"
      px={6}
      py={4}
      boxShadow={showShadow ? '0px 25px 50px -12px #00000040' : 'none'}
      borderBottom={!isExpanded ? '1px solid #E4E5E6' : 'none'}
      borderTop={!isExpanded ? '1px solid #E4E5E6' : 'none'}
      borderLeft={!isExpanded ? '1px solid #E4E5E6' : 'none'}
      borderRight={!isExpanded ? '1px solid #E4E5E6' : 'none'}
      display="flex"
      alignItems="center"
      gap="12px"
      onClick={toggleAssistFullScreen}
      cursor="pointer"
      width="100%"
    >
      <CompassOutlined fill="#282828" />
      <Text color="black" fontWeight="bold">
        Assist
      </Text>
      {firstName && lastName && (
        <Text color="#757575" fontWeight="normal">
          {`${firstName} ${lastName}`}
        </Text>
      )}
      <Box
        marginLeft="auto"
        transform={isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'}
        width="24px"
        height="24px"
      >
        <ChevronDown height="100%" width="100%" />
      </Box>
    </Box>
  )
}
