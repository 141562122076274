import React from 'react'
import {
  Flex,
  Text,
  HStack,
  IconButton,
  IconButtonProps
} from '@chakra-ui/react'
import { ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'

interface PaginationProps {
  current: number
  total: number
  onNext: () => void
  onPrev: () => void
}

const Button = ({
  icon,
  onClick,
  isDisabled
}: {
  icon: IconButtonProps['icon']
  onClick: () => void
  isDisabled: boolean
}) => {
  return (
    <IconButton
      m={0}
      size="xs"
      disabled={isDisabled}
      aria-label="back"
      variant="outline"
      borderRadius="0px"
      icon={icon}
      onClick={onClick}
      _focus={{ outline: 'none' }}
      _hover={{
        background: '#F5F5F7'
      }}
    />
  )
}

export const Pagination = ({
  current,
  total,
  onNext,
  onPrev
}: PaginationProps) => {
  return (
    <HStack columnGap="12px" spacing="0">
      <Text fontSize="sm">
        {current} of {total}
      </Text>
      <Flex gap="8px">
        <Button
          icon={<ArrowLeftIcon />}
          onClick={onPrev}
          isDisabled={current <= 1}
        />
        <Button
          icon={<ArrowRightIcon />}
          onClick={onNext}
          isDisabled={current >= total}
        />
      </Flex>
    </HStack>
  )
}
