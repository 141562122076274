import { EbcUpgradePlanModal } from '@containers'
import { action } from 'easy-peasy'

export const modalsModel = {
  modals: {
    assignWorksheets: {
      isOpen: false,
      clientId: null,
      openModal: action((state, { clientId }) => {
        state.clientId = clientId
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
        state.clientId = null
      })
    },
    clientInterventionList: {
      isOpen: false,
      clientId: null,
      interventions: [],
      openModal: action((state, { clientId, interventions }) => {
        state.clientId = clientId
        state.isOpen = true
        state.interventions = interventions
      }),
      closeModal: action(state => {
        state.isOpen = false
        state.clientId = null
      })
    },
    saveInterventions: {
      isOpen: false,
      openModal: action((state, { patientId }) => {
        state.isOpen = true
        state.patientId = patientId
      }),
      closeModal: action(state => {
        state.isOpen = false
        state.patientId = null
      })
    },
    interventionList: {
      isOpen: false,
      clientId: null,
      selectedInterventionIds: [],
      openModal: action(
        (state, { clientId, selectedInterventionIds, onAssign }) => {
          state.selectedInterventionIds = selectedInterventionIds
          state.clientId = clientId
          state.isOpen = true
          state.onAssign = onAssign
        }
      ),
      closeModal: action(state => {
        state.isOpen = false
        state.clientId = null
      })
    },
    assessmentScores: {
      isOpen: false,
      patientId: null,
      assessmentScoreIds: [],
      openModal: action((state, { patientId, assessmentScoreIds }) => {
        state.patientId = patientId
        state.assessmentScoreIds = assessmentScoreIds
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
        state.patientId = null
        state.assessmentScoreIds = []
      })
    },
    worksheetScores: {
      isOpen: false,
      patientId: null,
      worksheetScoreIds: [],
      preselectedCheckInId: null,
      preselectedCheckInScoreId: null,
      openModal: action(
        (
          state,
          {
            patientId,
            worksheetScoreIds,
            preselectedCheckInId,
            preselectedCheckInScoreId
          }
        ) => {
          state.patientId = patientId
          state.worksheetScoreIds = worksheetScoreIds
          state.preselectedCheckInId = preselectedCheckInId
          state.preselectedCheckInScoreId = preselectedCheckInScoreId
          state.isOpen = true
        }
      ),
      closeModal: action(state => {
        state.isOpen = false
        state.patientId = null
        state.worksheetScoreIds = []
        state.preselectedCheckInId = null
        state.preselectedCheckInScoreId = null
      })
    },
    archivePatient: {
      isOpen: false,
      patientId: null,
      onSuccess: null,
      onError: null,
      onExit: null,
      openModal: action(
        (
          state,
          { patientId, onSuccess = null, onError = null, onExit = null }
        ) => {
          state.patientId = patientId
          state.isOpen = true
          state.onSuccess = onSuccess
          state.onError = onError
          state.onExit = onExit
        }
      ),
      closeModal: action(state => {
        state.isOpen = false
        if (state.onExit) {
          state.onExit()
        }
      })
    },
    transferPatient: {
      isOpen: false,
      patientId: null,
      openModal: action((state, { patientId }) => {
        state.patientId = patientId
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    assignAdditionalClinician: {
      isOpen: false,
      patientId: null,
      openModal: action((state, { patientId }) => {
        state.patientId = patientId
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    adjustEnrollmentDate: {
      isOpen: false,
      patientId: null,
      openModal: action((state, { patientId }) => {
        state.patientId = patientId
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    adjustDeliveryDate: {
      isOpen: false,
      patientId: null,
      openModal: action((state, { patientId }) => {
        state.patientId = patientId
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    contactInfo: {
      isOpen: false,
      patientId: null,
      contactInfoRequirement: null,
      openModal: action((state, { patientId, contactInfoRequirement }) => {
        state.patientId = patientId
        state.isOpen = true
        state.contactInfoRequirement = contactInfoRequirement
      }),
      closeModal: action(state => {
        state.isOpen = false
        state.patientId = null
        state.contactInfoRequirement = null
      })
    },
    declineClient: {
      isOpen: false,
      clientId: null,
      firstName: null,
      openModal: action((state, { clientId, firstName }) => {
        state.clientId = clientId
        state.firstName = firstName
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    assignMeasures: {
      isOpen: false,
      clientId: null,
      openModal: action(
        (
          state,
          { clientId, measureType, allMeasures, onAssign, assigneeUser }
        ) => {
          state.clientId = clientId
          state.measureType = measureType
          state.allMeasures = allMeasures
          state.onAssign = onAssign
          state.assigneeUser = assigneeUser
          state.isOpen = true
        }
      ),
      closeModal: action(state => {
        state.isOpen = false
        state.clientId = null
        state.measureType = null
        state.allMeasures = []
        state.onAssign = null
        state.assigneeUser = {}
      })
    },
    upsertParticipant: {
      isOpen: false,
      participant: null,
      isMigration: false,
      onSubmit: () => {},
      openModal: action((state, { participant, onSubmit, isMigration }) => {
        state.participant = participant
        state.isOpen = true
        state.onSubmit = onSubmit
        state.isMigration = isMigration
      }),
      closeModal: action(state => {
        state.participant = null
        state.isOpen = false
        state.onSubmit = () => {}
        state.isMigration = false
      })
    },
    assessmentResult: {
      isOpen: false,
      clientId: null,
      assessmentScoreIds: [],
      preselectedAssessmentId: null,
      preselectedAssessmentScoreId: null,
      openModal: action(
        (
          state,
          {
            clientId,
            preselectedAssessmentId,
            preselectedAssessmentScoreId,
            assessmentScoreIds
          }
        ) => {
          state.isOpen = true
          state.clientId = clientId
          state.assessmentScoreIds = assessmentScoreIds
          state.preselectedAssessmentId = preselectedAssessmentId
          state.preselectedAssessmentScoreId = preselectedAssessmentScoreId
        }
      ),
      closeModal: action(state => {
        state.isOpen = false
        state.clientId = null
        state.assessmentScoreIds = []
        state.preselectedAssessmentId = null
        state.preselectedAssessmentScoreId = null
      })
    },
    choosePlan: {
      isOpen: false,
      openModal: action(state => {
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    documentationAutomationMarketingModal: {
      isOpen: false,
      openModal: action(state => {
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    manageScribePlan: {
      isOpen: false,
      clinicId: '',
      openModal: action((state, { clinicId }) => {
        state.isOpen = true
        state.clinicId = clinicId
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    docAutomationSessionLimitReached: {
      isOpen: false,
      openModal: action(state => {
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    ebcMarketingModal: {
      isOpen: false,
      clinicId: '',
      openModal: action((state, { clinicId }) => {
        state.isOpen = true
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    ebcUpgradePlan: {
      isOpen: false,
      hasAnyEbcPlan: false,
      clinicId: '',
      openModal: action((state, { hasAnyEbcPlan, clinicId }) => {
        state.isOpen = true
        state.hasAnyEbcPlan = hasAnyEbcPlan || state.hasAnyEbcPlan
        state.clinicId = clinicId || state.clinicId
      }),
      closeModal: action(state => {
        state.isOpen = false
      })
    },
    cancelIndividualPlan: {
      isOpen: false,
      planType: '',
      planName: '',
      clinicId: '',
      subscriptionId: '',
      openModal: action(
        (state, { planType, planName, clinicId, subscriptionId }) => {
          state.planType = planType
          state.planName = planName
          state.clinicId = clinicId
          state.subscriptionId = subscriptionId
          state.isOpen = true
        }
      ),
      closeModal: action(state => {
        state.isOpen = false
      })
    }
  }
}
